import './assets/css/style.css'

// import './assets/js/particles.min.js'
// import './assets/js/sweet-scroll.min.js'

import 'bootstrap/dist/css/bootstrap.min.css'

import 'popper.js'

import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import './assets/fontawesome/all.js'

import './assets/js/worker.js'
import './assets/js/main.js'

require('./favicon.ico')
